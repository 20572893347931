import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Link,
  Text,
  HStack,
  Stack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

//  METAGAME IMAGES
// https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/
// https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/
// https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/
// https://bafybeiggmevfo3wujbvt3xqcvaudhrbs5wug7heigsxnnbkueburlefena.ipfs.infura-ipfs.io/

function CardContact1() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <HStack>
        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            h={"450px"}
            w={"full"}
            bg={useColorModeValue("purple.300", "purple.900")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"110px"}
              w={"full"}
              src={
                // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
              }
              alt=" MetaCollab "
              // objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-12}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/ "
                }
                alt={" MetaGame "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading
                  color={"purple.500"}
                  fontSize={"xl"}
                  fontWeight={700}
                  fontFamily={"Exo 2, san-serif"}
                >
                  Doer One
                </Heading>
                <Text color={"gray.500"} fontSize={"sm"}>
                  {" "}
                  MetaCollab Doer 1{" "}
                </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Rank
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Cred
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#151f21", "blue.400")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                <Link
                  target="_blank"
                  href="https://buildagig.metacollab.freeweb3.com/"
                >
                  Handshake
                </Link>
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            h={"450px"}
            w={"full"}
            bg={useColorModeValue("purple.200", "purple.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"110px"}
              w={"full"}
              src={
                // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
              }
              alt=" MetaCollab "
              // objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-12}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeiaule52hkimehkorjam6r52mkiyruhaqsq5bnfnggqrynbzfnduly.ipfs.infura-ipfs.io/ "
                }
                alt={" MetaGame "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading
                  color={"purple.500"}
                  fontSize={"xl"}
                  fontWeight={700}
                  fontFamily={"Exo 2, san-serif"}
                >
                  Doer Two
                </Heading>
                <Text color={"gray.500"} fontSize={"sm"}>
                  {" "}
                  MetaCollab Doer 2{" "}
                </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Rank
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Cred
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#151f21", "blue.400")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                <Link
                  target="_blank"
                  href="https://buildagig.metacollab.freeweb3.com/"
                >
                  Handshake
                </Link>
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            h={"450px"}
            w={"full"}
            bg={useColorModeValue("purple.300", "purple.900")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"110px"}
              w={"full"}
              src={
                // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
              }
              alt=" MetaCollab "
              // objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-12}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeih4g5hqfjj2wwtqlzom7ci6pvxzv3rxlehnxd3i4ua66e5pckqp2q.ipfs.infura-ipfs.io/ "
                }
                alt={" MetaGame "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading
                  color={"purple.500"}
                  fontSize={"xl"}
                  fontWeight={700}
                  fontFamily={"Exo 2, san-serif"}
                >
                  Doer Thr3
                </Heading>
                <Text color={"gray.500"} fontSize={"sm"}>
                  {" "}
                  MetaCollab Doer 3{" "}
                </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Rank
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Cred
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#151f21", "blue.400")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                <Link
                  target="_blank"
                  href="https://buildagig.metacollab.freeweb3.com/"
                >
                  Handshake
                </Link>
              </Button>
            </Box>
          </Box>
        </Center>
        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            h={"450px"}
            w={"full"}
            bg={useColorModeValue("purple.200", "purple.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"110px"}
              w={"full"}
              src={
                " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
              }
              alt=" MetaCollab "
              // objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-12}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/ "
                }
                alt={" MetaGame "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading
                  color={"purple.500"}
                  fontSize={"xl"}
                  fontWeight={700}
                  fontFamily={"Exo 2, san-serif"}
                >
                  Doer Four
                </Heading>
                <Text color={"gray.500"} fontSize={"sm"}>
                  {" "}
                  MetaCollab Doer 4{" "}
                </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Rank
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Cred
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#151f21", "blue.400")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                <Link
                  target="_blank"
                  href="https://buildagig.metacollab.freeweb3.com/"
                >
                  Handshake
                </Link>
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            h={"450px"}
            w={"full"}
            bg={useColorModeValue("purple.300", "purple.900")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"110px"}
              w={"full"}
              src={
                " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
              }
              alt=" MetaCollab "
              // objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-12}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeib4lcuqh2iylaj6ywqxn4ccgpz5faiirppitodl5h7b6kmxnevppa.ipfs.infura-ipfs.io/ "
                }
                alt={" MetaGame "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading
                  color={"purple.500"}
                  fontSize={"xl"}
                  fontWeight={700}
                  fontFamily={"Exo 2, san-serif"}
                >
                  Doer Five
                </Heading>
                <Text color={"gray.500"} fontSize={"sm"}>
                  {" "}
                  MetaCollab Doer 5{" "}
                </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Rank
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Cred
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#151f21", "blue.400")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                <Link
                  target="_blank"
                  href="https://buildagig.metacollab.freeweb3.com/"
                >
                  Handshake
                </Link>
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            h={"450px"}
            w={"full"}
            bg={useColorModeValue("purple.200", "purple.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"110px"}
              w={"full"}
              src={
                " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
              }
              alt=" MetaCollab "
              // objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-12}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq.ipfs.infura-ipfs.io "
                }
                alt={" MetaGame "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading
                  color={"purple.500"}
                  fontSize={"xl"}
                  fontWeight={700}
                  fontFamily={"Exo 2, san-serif"}
                >
                  Doer Six
                </Heading>
                <Text color={"gray.500"} fontSize={"sm"}>
                  {" "}
                  MetaCollab Doer 6{" "}
                </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Rank
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Cred
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#151f21", "blue.400")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                <Link
                  target="_blank"
                  href="https://buildagig.metacollab.freeweb3.com/"
                >
                  Handshake
                </Link>
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            h={"450px"}
            w={"full"}
            bg={useColorModeValue("purple.300", "purple.900")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"110px"}
              w={"full"}
              src={
                " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
              }
              alt=" MetaCollab "
              // objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-12}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq.ipfs.infura-ipfs.io/ "
                }
                alt={" MetaGame "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading
                  color={"purple.500"}
                  fontSize={"xl"}
                  fontWeight={700}
                  fontFamily={"Exo 2, san-serif"}
                >
                  Doer Sev
                </Heading>
                <Text color={"gray.500"} fontSize={"sm"}>
                  {" "}
                  MetaCollab Doer 7{" "}
                </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Rank
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Cred
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#151f21", "blue.400")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                <Link
                  target="_blank"
                  href="https://buildagig.metacollab.freeweb3.com/"
                >
                  Handshake
                </Link>
              </Button>
            </Box>
          </Box>
        </Center>
      </HStack>
    </div>
  );
}
export default CardContact1;
