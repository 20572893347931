import React from "react";
import {
  Box,
  Heading,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Image,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";


function Tabs8() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Box >
        <Heading ml="5" fontSize="2xl" color="cyan">
          MetaGame is Made up of Guild Members
        </Heading>

        <Box p={4} display={{ md: "flex" }}>
          <Box flexShrink={0}>
            <Tabs defaultIndex={4}>
              <TabPanels>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafkreic5mdegc7kyvhfonrteh7khvkprc6yc7lc4mdqinfjdkz2o3uwl2u.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeiagnt3b2nxgu2vh7skmbruthedv73o6ek7vyzpbkjh7rujfm3cici.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeicv4pk3axs2xequepsfbxnvkdyesm3gm6dqf2sk2mycgx4flybi2a.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeicivgxjjb5jng2ovkpd3urvxay6zcwuxawvrx6cp42c33brwv5x4q.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeidsu7licdvguf5tgyigtpcedjgy6tegmdlklvtq6j5hurwrsr3sjm.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeigzgwfqaroaiszsmfgi47w5rzvvgevykysof7fdyk5d6q4bxobsti.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeidlmfis2lf2oqsewhfyvwtzkfgr4dyu7u5ebue32vn27slr72g7he.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeihiqf6qmvfdeltwoee5yr4jhjddksj73kxcdnltinsee6krfmqy3a.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
                <TabPanel>
                  <Image
                    boxSize="300px"
                    fit="cover"
                    src=" https://bafybeiejwxdtue664xxw2zcr3223wsbqhlxfqogwasv5cz4mxmveqv2yvu.ipfs.infura-ipfs.io/ "
                  />
                </TabPanel>
              </TabPanels>
              <TabList>
                <Tab> Builders Guild </Tab>
                <Tab> Designers Guild </Tab>
                <Tab> Writers Guild </Tab>
                <Tab> Artists Guild </Tab>
                <Tab> Innkeepers Guild </Tab>
                <Tab> Shillers Guild </Tab>
                <Tab> Fairies Guild </Tab>
                <Tab> Headhunters Guild </Tab>
                <Tab> Necromancers Guild </Tab>
              </TabList>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
export default Tabs8;
