import React, { useState } from "react";
import {
  Button,
  Input,
  VStack,
  Table,
  TableCaption,
  Box,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Spinner,
  Stack,
  Text,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";
// import background from "./images/mg-bckg-12-1280w.png";
import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";



const FieldsTable = ({ fields, deleteItem, ...props }) => (
  <Table {...props}>
    <TableCaption placement="top">data</TableCaption>
    <Thead>
      <Tr>
        <Th> MetaGameKey </Th>
        <Th> Web3Value </Th>
        <Th> MetaGame Functions </Th>
      </Tr>
    </Thead>
    <Tbody>
      {fields.map(({ key, value }, idx) => (
        <Tr key={idx}>
          <Td> 300 </Td>
          <Td> 300 </Td>
          <Td>
            <IconButton
              colorScheme="white"
              icon={<DeleteIcon />}
              onClick={() => deleteItem(key)}
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
const ObjectCreator = (props) => {
  const [fields, setFields] = useState([]);
  const deleteItem = (e) => {
    let fieldsCurrent = [...fields];
    const index = fields.indexOf(fields.find((x) => x.key === e));
    if (index > -1) {
      fieldsCurrent.splice(index, 1);
      setFields(fieldsCurrent);
      console.log("Removed", e);
    }
  };
  const nameProject = () => {
    const { value: name } = document.getElementById("name");
    const obj = { key: "name", value: name };
    setFields((fields) => [...fields, obj]);
  };
  function addItem() {
    let { value: key } = document.getElementById("key");
    let { value } = document.getElementById("value");
    const obj = { key, value };
    setFields((fields) => [...fields, obj]);
    document.getElementById("key").value = "";
    document.getElementById("value").value = "";
  }
  async function createObject() {
    const result = Object.fromEntries(
      fields.map((k) => [k["key"], k["value"]])
    );
    console.log(JSON.stringify(result));
    await props.createEntry(result["name"], JSON.stringify(result));
  }
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

    <Box>
      {/* <hr className="solid" /> */}
      <Box>
        <Box>
          {fields.length > 0 ? (
            <Box>
              <FieldsTable {...{ fields, deleteItem }} />
              <VStack>
                <Input
                  w="90%"
                  colorScheme="white"
                  variant="outline"
                  id="key"
                  placeholder="Classificator"
                />
                <Input
                  w="90%"
                  colorScheme="white"
                  variant="outline"
                  id="value"
                  placeholder="value"
                />
                <Button colorScheme="white" variant="outline" onClick={addItem}>
                  Add Property
                </Button>
                <Divider />
                <Button
                  colorScheme="white"
                  variant="outline"
                  onClick={createObject}
                >
                  Finish Object
                </Button>
              </VStack>
              <Input
                type="checkbox"
                value={props.wrap}
                checked={props.wrap}
                onChange={() => props.setWrap((w) => !w)}
              />
              Wrap value in a DAG
              <br />
              <Button onClick={createObject}> Finish Object </Button>
            </Box>
          ) : (
            <Box>
              <VStack>
                <Input
                  mt="4"
                  w="40%"
                  variant="outline"
                  colorScheme="white"
                  id="name"
                  placeholder="Gig Address"
                ></Input>
                <Button
                  w="20%"
                  variant="outline"
                  colorScheme="green"
                  onClick={() => nameProject()}
                >
                  {" "}
                  Open Gig{" "}
                </Button>
              </VStack>
              <Stack justify="right" direction="row" spacing={4}>
                <Spinner speed="2s" size="md" color="pink.500" />
                <Spinner speed="3s" size="md" color="blue.500" />
                <Spinner speed="4s" size="md" color="purple.500" />
              </Stack>
              <br />
              <Stack justify="right" direction="row" spacing={4}>
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="gray.200"
                  color="pink.500"
                  size="xl"
                />
                <Spinner
                  thickness="4px"
                  speed="3s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
                <Spinner
                  thickness="4px"
                  speed="4s"
                  emptyColor="gray.200"
                  color="purple.500"
                  size="xl"
                />
              </Stack>
              <Stack justify="right" direction="row" spacing={4}>
                <Text mt={2} color="gray.100">
                  Please wait while your transaction processes. It may take a
                  while.
                </Text>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
    </ div>    

  );
};
export default ObjectCreator;
