import { React } from "react";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

import { HamburgerIcon, CloseIcon, AddIcon } from "@chakra-ui/icons";
const Links = ["Live Dashboard", "Current Gigs", "Users", "Funders"];
const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("blue.100", "blue.700"),
    }}
    href={" # "}
  >
    {children}
  </Link>
);
function Header0() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <>
        <Box bg="blue.800" px={4}>
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={"center"}>
              <Box color="cyan" fontSize="2xl" fontWeight="bold">
                {" "}
                MetaCollab{" "}
              </Box>
              <HStack
                as={"nav"}
                spacing={4}
                display={{ base: "none", md: "flex" }}
              >
                {Links.map((link) => (
                  <NavLink key={link}>{link}</NavLink>
                ))}
              </HStack>
            </HStack>
            <Flex alignItems={"center"}>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                variant={"solid"}
                colorScheme={"purple"}
                size={"sm"}
                mr={4}
                leftIcon={<AddIcon />}
              >
                Gig Actions
              </MenuButton>
              <MenuList
                minWidth="240px"
                bgColor="gray.300"
                color="blue"
                fontStyle="Exo 2, san-serif"
              >
                <MenuOptionGroup
                  defaultValue="sendH"
                  fontStyle="Exo 2, san-serif"
                  color="gray.800"
                  fontSize="lg"
                  title=" Gig Actions"
                  type="checkbox"
                >
                  <MenuOptionGroup
                    title=" Blockchain Transactions"
                    type="checkbox"
                  >
                    <MenuItemOption value="sendH">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Send a Handshake{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="signH">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Sign a Handshake{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="createG">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Create a Gig{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="signG">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Sign a Gig{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="startG">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Start a Gig{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="cancelG">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Cancel a Gig{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="completeG">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Complete a Gig{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="addR">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Add a Resolver{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="confR">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Confirm a Resolver{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="lockG">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Lock a Gig{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="add3P">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Add a Third-Party Beneficiary{" "}
                      </Link>
                    </MenuItemOption>
                    <MenuItemOption value="resolveG">
                      {" "}
                      <Link
                        target="_blank"
                        href="https://buildagig.metacollab.freeweb3.com/"
                      >
                        Resolve a Gig{" "}
                      </Link>
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuOptionGroup>
                <MenuDivider />

                <MenuOptionGroup
                  defaultValue="inCaState"
                  color="gray.800"
                  fontSize="lg"
                  title=" Jurisdiction"
                  type="radio"
                >
                  <MenuItemOption value="inCaState">
                    {" "}
                    In California{" "}
                  </MenuItemOption>
                  <MenuItemOption value="outofCaState">
                    {" "}
                    In USA, not California{" "}
                  </MenuItemOption>
                  <MenuItemOption value="outofUSA">
                    {" "}
                    Outside USA{" "}
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>




              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"xl"}
                    src={
                      // " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
                      " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
                    }
                  />
                </MenuButton>
                <MenuList color="blue">
                  <MenuItem> Link 1 </MenuItem>
                  <MenuItem> Link 2 </MenuItem>
                  <MenuDivider />
                  <MenuItem> Link 3 </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
          {isOpen ? (
            <Box pb={4} display={{ md: "none" }}>
              <Stack as={"nav"} spacing={4}>
                {Links.map((link) => (
                  <NavLink key={link}>{link}</NavLink>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>
        <Box p={4}>
          MetaCollab provides value and service supporting the fourth industrial
          revolution. Web3 is a set of technologies that publicly interoperate
          through blockchain and node based computing systems.
        </Box>
      </>
    </div>
  );
}
export default Header0;
